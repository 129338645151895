export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6686aea51d712a002335db8d",
				"6686aea51d712a002335db90"
			]
		},
		"6686aea51d712a002335db8d": {
			"id": "6686aea51d712a002335db8d",
			"name": "404",
			"pageUrl": "404"
		},
		"6686aea51d712a002335db90": {
			"id": "6686aea51d712a002335db90",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"title": "IA - Сервис защиты фьючерсных позиций",
			"description": "IndexAny.com - Сервис для трейдеров на криптовалютных биржах, который поможет защитить ваш фьючерсный баланс от опасных просадок.",
			"og:description": "IndexAny.com - Сервис для трейдеров на криптовалютных биржах, который поможет защитить ваш фьючерсный баланс от опасных просадок.",
			"customSitemapDomain": "https://indexany.com"
		}
	}
}