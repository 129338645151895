import React from 'react';
import {Form, Input, Button, Modal, Select} from 'antd';
import {Box, Text} from "@quarkly/widgets";
import postToTelegram from "../../Helpers/PostToTelegram";
import {Option} from "antd/es/mentions";

const {TextArea} = Input;

const layout = {
    labelCol: {
        span: 0,
    },
    // wrapperCol: {
    //     span: 16,
    // },
};

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
    required: '${label} обязательно!',
    types: {
        email: '${label} неверный email!',
        number: '${label} неверный номер!',
    },
    number: {
        range: '${label} число должно быть от ${min} до ${max}',
    },
    message: {
        range: '${label} длина текста от ${min} до ${max}',
        length: '${label} длина текста от ${min} до ${max}',
    }
};

/* eslint-enable no-template-curly-in-string */

function WishListForm(props) {

    const onFinish = (values) => {
        // gnerate random number for ref
        const ref = Math.floor(Math.random() * 1000000);
        // console.log(values);
        postToTelegram(values);
        Modal.success({
            content: (<>
                <h3>Ваше сообщение отправлено!</h3>
                <br/>
                Если Вы хотите просунуться выше по списку ожидания, пригласите друзей по ссылке:<br/><br/>
                https://indexany.com/?i={ref}
            </>),
        });
        props.onFinish();
    }

    return (
        <Form
            {...layout}
            scrollToFirstError
            name="contact-form"
            onFinish={onFinish}
            style={{
                marginTop: "20px",
            }}
            validateMessages={validateMessages}
        >
            <Text margin="0px 0px 16px 0px" font="normal 400 20px/1.5 --fontFamily-googleRoboto" color="--grayD1"
                  sm-margin="0px 0px 8px 0px">
                Имя
            </Text>
            <Form.Item
                name='name'
                // label="Имя"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input
                    placeholder="Ваше имя"
                    style={{
                        width: "100%",
                        padding: "10px 16px 10px 16px",
                        borderRadius: "8px",
                        borderWidth: "2px",
                        margin: "0px 0px 0px 0px",
                        smMargin: "0px 0px 0px 0px",
                        background: "rgba(255, 255, 255, 0)",
                        borderColor: "rgb(97,122,248)",
                        borderStyle: "solid",
                        color: "black",
                    }}
                />
            </Form.Item>
            <Text margin="0px 0px 16px 0px" font="normal 400 20px/1.5 --fontFamily-googleRoboto" color="--grayD1"
                  sm-margin="0px 0px 8px 0px">
                E-mail
            </Text>
            <Form.Item
                name='email'
                // label="E-mail"
                rules={[
                    {
                        required: true,
                        type: 'email',
                    },
                ]}
            >
                <Input
                    placeholder="Ваш e-mail"
                    style={{
                        width: "100%",
                        padding: "10px 16px 10px 16px",
                        borderRadius: "8px",
                        borderWidth: "2px",
                        margin: "0px 0px 0px 0px",
                        smMargin: "0px 0px 0px 0px",
                        background: "rgba(255, 255, 255, 0)",
                        borderColor: "rgb(97,122,248)",
                        borderStyle: "solid",
                        color: "black"
                    }}
                />
            </Form.Item>
            <Text font="normal 400 20px/1.5 --fontFamily-googleRoboto" color="--grayD1" sm-margin="0px 0px 8px 0px"
                  margin="0px 0px 16px 0px">
                Тариф
            </Text>
            <Form.Item
                name='package'
                rules={[
                    {
                        required: true,
                    },
                ]}
                // label="Вопрос"
            >
                <Select
                    placeholder="Выберите тариф"
                    style={{
                        width: "100%",
                        // padding: "10px 16px 10px 16px",
                        borderRadius: "8px",
                        // borderWidth: "2px",
                        margin: "0px 0px 24px 0px",
                        smMargin: "0px 0px 14px 0px",
                        background: "rgba(255, 255, 255, 0)",
                        borderColor: "rgb(97,122,248)",
                        borderStyle: "solid",
                        color: "black",
                        // height: "160px"
                    }}
                >
                    <Option value="1"><strong>Базовый</strong></Option>
                    <Option value="2"><strong>Продвинутый</strong></Option>
                    <Option value="3"><strong>Надежный</strong></Option>
                    <Option value="4"><strong>Солидный</strong></Option>
                    <Option value="0">Просто задать вопрос</Option>
                </Select>
            </Form.Item>
            <Text font="normal 400 20px/1.5 --fontFamily-googleRoboto" color="--grayD1" sm-margin="0px 0px 8px 0px"
                  margin="0px 0px 16px 0px">
                Вопрос
            </Text>
            <Form.Item
                name='message'
                // rules={[
                //     {
                //         min: 20,
                //         max: 1000,
                //         required: true,
                //     },
                // ]}
                // label="Вопрос"
            >
                <TextArea
                    placeholder="Напишите биржи с которыми Вы хотите работать, или задайте вопрос.."
                    style={{
                        width: "100%",
                        padding: "10px 16px 10px 16px",
                        borderRadius: "8px",
                        borderWidth: "2px",
                        margin: "0px 0px 24px 0px",
                        smMargin: "0px 0px 14px 0px",
                        background: "rgba(255, 255, 255, 0)",
                        borderColor: "rgb(97,122,248)",
                        borderStyle: "solid",
                        color: "black",
                        height: "160px"
                    }}
                />
            </Form.Item>
            <Form.Item
                // wrapperCol={{
                //     ...layout.wrapperCol,
                //     offset: 2,
                // }}
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    type="primary" htmlType="submit"
                    style={{
                        font: "normal 400 18px/1.5 --fontFamily-googleRoboto",
                        // width: "70%",
                        padding: "9px 82px 9px 81px",
                        borderRadius: "4px",
                        transition: "background-color 0.1s ease 0s",
                    }}
                >
                    Отправить
                </Button>
            </Form.Item>
            <Box
                min-width="10px"
                min-height="10px"
                display="flex"
                sm-flex-direction="column"
                justify-content="flex-end"
                padding="12px 0px 0px 0px"
                sm-align-items="center"
                lg-justify-content="center"
            >
            </Box>
        </Form>
    )
}

export default WishListForm;