import React from "react";
import theme from "theme";
import {Theme, Image, LinkBox, Box, Section, Text, Button} from "@quarkly/widgets";
import {Helmet} from "react-helmet";
import {GlobalQuarklyPageStyles} from "global-page-styles";
import {RawHtml, Override} from "@quarkly/components";
import {Modal} from "antd";
import WishListForm from "../components/WishListForm";

export default (() => {

    function showForm(e) {
        e.preventDefault();
        const md = Modal.confirm({
            width: 800,
            style: {
                marginTop: '-80px',
                // paddingTop: '0px',
            },
            title: 'Заполните форму',
            content: <WishListForm
                onFinish={() => {
                    md.destroy();
                }}
            />,
            footer: null,
        })
    }

    return <Theme theme={theme}>
        <GlobalQuarklyPageStyles pageUrl={"index"}/>
        <Helmet>
            <title>
                IA - Сервис защиты фьючерсных позиций
            </title>
            <meta name={"description"}
                  content={"IndexAny.com - Сервис для трейдеров на криптовалютных биржах, который поможет защитить ваш фьючерсный баланс от опасных просадок."}/>
            <meta property={"og:description"}
                  content={"IndexAny.com - Сервис для трейдеров на криптовалютных биржах, который поможет защитить ваш фьючерсный баланс от опасных просадок."}/>
            <link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
                  type={"image/x-icon"}/>
        </Helmet>
        <Section padding="0px 0 30px 0" background="--color-light">
            <Override
                slot="SectionContent"
                order="0"
                align-content="center"
                flex-wrap="no-wrap"
                justify-content="center"
                text-align="center"
                flex="0 1 auto"
                align-items="center"
                md-align-items="center"
                md-justify-content="center"
                md-flex-direction="column"
                md-align-content="center"
                md-text-align="center"
            />
            <Box
                margin="40px 0px 0px 0px"
                display="flex"
                flex-direction="row"
                flex-wrap="wrap"
                align-self="auto"
                order="0"
                flex="0 1 auto"
                align-items="center"
                justify-content="space-around"
                sm-flex="0 1 auto"
                sm-align-self="auto"
                sm-align-items="center"
                sm-justify-content="space-between"
                sm-flex-wrap="wrap"
                sm-display="flex"
                sm-flex-direction="column"
                sm-align-content="space-around"
            >
                <Box
                    position="relative"
                    display="block"
                    flex-direction="column"
                    margin="0px 0px 0px 15px"
                    lg-margin="0px 0px 0px 0px"
                    sm-width="calc(100% / 2)"
                    sm-margin="0px 0px 0px 0px"
                    width="calc(100% / 6)"
                    md-width="calc(100% / 3)"
                    mix-blend-mode="normal"
                    sm-align-self="auto"
                    sm-flex="0 1 auto"
                >
                    <LinkBox href="https://accounts.binance.com/en/register?ref=OSHDFY9J" target="_blank">
                        <Image
                            src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/Binance_logo.svg?v=2024-06-03T18:41:12.318Z"
                            border-radius="16px"
                            max-width="100%"
                            title="binance"
                            alt="binance"
                            sm-align-self="center"
                            sm-height="80px"
                            md-height="80px"
                        />
                    </LinkBox>
                </Box>
                <Box
                    position="relative"
                    display="flex"
                    flex-direction="column"
                    margin="0px 0px 0px 20px"
                    lg-margin="0px 0px 0px 0px"
                    sm-width="calc(100% / 2)"
                    sm-margin="0px 0px 0px 0px"
                    width="8%"
                    md-width="calc(100% / 3)"
                >
                    <LinkBox href="https://www.bybit.com/uk-UA/invite/?ref=0NN1MN" target="_blank">
                        <Image
                            src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18:38:08.198Z"
                            border-radius="16px"
                            max-width="100%"
                            align-self="auto"
                            order="0"
                            display="inline-block"
                            alt="bybit"
                            title="bybit"
                            sm-height="80px"
                            md-height="80px"
                            srcSet="https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18%3A38%3A08.198Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18%3A38%3A08.198Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18%3A38%3A08.198Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18%3A38%3A08.198Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18%3A38%3A08.198Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18%3A38%3A08.198Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18%3A38%3A08.198Z&quality=85&w=3200 3200w"
                            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                    </LinkBox>
                </Box>
                <Box
                    position="relative"
                    display="flex"
                    flex-direction="column"
                    margin="0px 0px 0px 20px"
                    lg-margin="0px 0px 0px 0px"
                    sm-width="calc(100% / 2)"
                    sm-margin="0px 0px 0px 0px"
                    md-width="calc(100% / 3)"
                    flex="0 1 auto"
                    order="0"
                    align-self="center"
                    width="10%"
                >
                    <LinkBox
                        href="https://www.htx.com/en-us/v/register/double-invite/web/?inviter_id=11345710&invite_code=rc2c5223"
                        target="_blank">
                        <Image
                            src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18:42:22.752Z"
                            border-radius="16px"
                            max-width="100%"
                            alt="huobi"
                            title="huobi"
                            sm-height="80px"
                            md-height="80px"
                            srcSet="https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18%3A42%3A22.752Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18%3A42%3A22.752Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18%3A42%3A22.752Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18%3A42%3A22.752Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18%3A42%3A22.752Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18%3A42%3A22.752Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18%3A42%3A22.752Z&quality=85&w=3200 3200w"
                            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                    </LinkBox>
                </Box>
                <Box
                    position="relative"
                    display="flex"
                    flex-direction="column"
                    margin="0px 0px 0px 20px"
                    lg-margin="0px 0px 0px 0px"
                    sm-width="calc(100% / 2)"
                    sm-margin="0px 0px 0px 0px"
                    width="10%"
                    md-width="calc(100% / 3)"
                    padding="0px 0px 0px 0px"
                >
                    <LinkBox href="https://www.kucoin.com/r/rf/QBAEVVMH" target="_blank">
                        <Image
                            src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18:43:38.085Z"
                            border-radius="16px"
                            max-width="100%"
                            alt="kucoin"
                            title="kucoin"
                            sm-height="80px"
                            md-height="80px"
                            srcSet="https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18%3A43%3A38.085Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18%3A43%3A38.085Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18%3A43%3A38.085Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18%3A43%3A38.085Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18%3A43%3A38.085Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18%3A43%3A38.085Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18%3A43%3A38.085Z&quality=85&w=3200 3200w"
                            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                    </LinkBox>
                </Box>
                <Box
                    position="relative"
                    display="flex"
                    flex-direction="column"
                    margin="0px 0px 0px 20px"
                    lg-margin="0px 0px 0px 0px"
                    sm-width="calc(100% / 2)"
                    sm-margin="0px 0px 0px 0px"
                    width="8%"
                    md-width="calc(100% / 3)"
                >
                    <LinkBox href="https://www.okx.com/ua/join/53775592" target="_blank">
                        <Image
                            src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/okx.svg?v=2024-06-03T18:38:08.193Z"
                            border-radius="16px"
                            max-width="100%"
                            alt="okx"
                            title="okx"
                            sm-height="60px"
                            md-height="60px"
                        />
                    </LinkBox>
                </Box>
            </Box>
        </Section>
        <Section padding="50px 0 60px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
            <Box
                min-width="100px"
                min-height="100px"
                lg-width="100%"
                display="flex"
                flex-direction="column"
                align-items="center"
                sm-align-items="center"
                flex="0 0 auto"
            >
                <Text
                    margin="0px 0px 20px 0px"
                    font="normal 100 36px/1.5 --fontFamily-googleRubikMonoOne"
                    sm-font="normal 700 42px/1.2 --fontFamily-sans"
                    padding="0px 150px 0px 150px"
                    text-align="center"
                    color="--darkL2"
                    lg-padding="0px 0 0px 0"
                    md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
                >
                    Страхуйте свои ФЬЮЧЕРСНЫЕ позиции автоматически, даже когда вы спите
                </Text>
                <Text
                    margin="0px 0px 50px 0px"
                    font="normal 500 24px/1.5 --fontFamily-googleIbmPlexMono"
                    color="--darkL2"
                    padding="0px 100px 0px 100px"
                    text-align="center"
                    lg-padding="0px 0 0px 0"
                    md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
                >
                    Мы предлагаем сервис для трейдеров на криптовалютных биржах, который поможет защитить ваш фьючерсный
                    баланс от опасных просадок. Наш робот постоянно отслеживает состояние вашего счета и автоматически
                    сокращает открытые позиции в соответствии с заданными вами параметрами. С нашим сервисом вы можете
                    сосредоточиться на прибыльной торговле, доверив контроль рисков надежной системе.
                </Text>
            </Box>
            <Image
                src="https://uploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10:50:34.643Z"
                display="block"
                height="100%"
                object-fit="cover"
                border-radius="35px"
                srcSet="https://smartuploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10%3A50%3A34.643Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10%3A50%3A34.643Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10%3A50%3A34.643Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10%3A50%3A34.643Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10%3A50%3A34.643Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10%3A50%3A34.643Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6686aea51d712a002335db88/images/save_last.png?v=2024-07-05T10%3A50%3A34.643Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
        </Section>
        <Section padding="60px 0 50px 0" lg-padding="80px 0 70px 0" quarkly-title="Stages/Steps-5"
                 background="--color-lightD1">
            <Override slot="SectionContent"/>
            <Text
                margin="0px 0px 90px 0px"
                font="normal 100 36px/1.2 --fontFamily-googleRubikMonoOne"
                color="--darkL1"
                border-color="--color-dark"
                text-align="center"
                lg-margin="0px 0px 50px 0px"
            >
                Как это работает
            </Text>
            <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                grid-template-columns="repeat(3, 1fr)"
                md-grid-template-columns="1fr"
                md-grid-template-rows="auto"
                md-grid-gap={0}
                lg-padding="0px 0 0px 0"
                flex-direction="column"
                align-self="center"
            >
                <Box
                    min-width="100px"
                    min-height="100px"
                    display="flex"
                    flex-direction="row"
                    padding="0px 50px 0 0"
                    lg-padding="0px 25px 0 0"
                >
                    <Box
                        min-width="40px"
                        min-height="40px"
                        background="--color-darkL1"
                        display="flex"
                        width="40px"
                        height="40px"
                        border-radius="50%"
                        margin="0px 0px 30px 0px"
                        color="--darkL2"
                        align-items="center"
                        justify-content="center"
                        position="relative"
                        z-index="3"
                    >
                        <Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
                            1
                        </Text>
                    </Box>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        margin="0px 0px 0px -20px"
                        border-width="0 0 0 1px"
                        border-style="solid"
                        border-color="#c8ced7"
                        padding="0px 0px 0px 20px"
                    >
                        <Text
                            margin="0 0 15px 25px"
                            border-color="--color-light"
                            color="--darkL1"
                            font="--headline3"
                            text-align="left"
                        >
                            Подключите счет
                        </Text>
                        <Text
                            margin="0 0 0 25px"
                            font="normal 500 24px/1.5 --fontFamily-googleIbmPlexMono"
                            color="#65696d"
                            text-align="left"
                            border-color="#7a7c7f"
                            padding="0px 0px 50px 0px"
                        >
                            Подключите свой аккаунт криптобиржи через безопасные ключи API.
                        </Text>
                    </Box>
                </Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    display="flex"
                    flex-direction="row"
                    padding="0px 50px 0 0"
                    lg-padding="0px 25px 0 0"
                >
                    <Box
                        min-width="40px"
                        min-height="40px"
                        background="--color-darkL1"
                        display="flex"
                        align-items="center"
                        justify-content="center"
                        width="40px"
                        height="40px"
                        border-radius="50%"
                        margin="0px 0px 30px 0px"
                        color="--darkL2"
                        position="relative"
                        z-index="3"
                    >
                        <Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
                            2
                        </Text>
                    </Box>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        margin="0px 0px 0px -20px"
                        padding="0px 0px 0px 20px"
                        border-color="#c8ced7"
                        border-style="solid"
                        border-width="0 0 0 1px"
                    >
                        <Text
                            margin="0 0 15px 25px"
                            border-color="--color-light"
                            color="--darkL1"
                            font="--headline3"
                            text-align="left"
                        >
                            Настройте правила защиты
                        </Text>
                        <Text
                            margin="0 0 0 25px"
                            font="normal 500 24px/1.5 --fontFamily-googleIbmPlexMono"
                            color="#65696d"
                            text-align="justify"
                            border-color="#7a7c7f"
                            padding="0px 0px 50px 0px"
                        >
                            Задайте персональные правила контроля позиций: при каком уровне просадки и насколько
                            сократить открытые позиции. Например, закрыть 50% позиций при просадке в 5%, а потом закрыть
                            все позиции при 10% просадки.
                        </Text>
                    </Box>
                </Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    display="flex"
                    flex-direction="row"
                    padding="0px 50px 30px 0"
                    sm-padding="0px 0 0 0"
                    lg-padding="0px 25px 0 0"
                    md-margin="0px 0px 30px 0px"
                    sm-margin="0px 0px 20px 0px"
                >
                    <Box
                        min-width="40px"
                        min-height="40px"
                        background="--color-darkL1"
                        display="flex"
                        align-items="center"
                        justify-content="center"
                        width="40px"
                        height="40px"
                        border-radius="50%"
                        margin="0px 0px 30px 0px"
                        color="--darkL2"
                    >
                        <Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
                            3
                        </Text>
                    </Box>
                    <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
                        <Text
                            margin="0 0 15px 25px"
                            border-color="--color-light"
                            color="--darkL1"
                            font="--headline3"
                            text-align="left"
                        >
                            Все под контролем
                        </Text>
                        <Text
                            margin="0 0 0 25px"
                            font="normal 500 24px/1.5 --fontFamily-googleIbmPlexMono"
                            color="#65696d"
                            text-align="justify"
                            border-color="#7a7c7f"
                        >
                            Робот начинает круглосуточно следить за вашим балансом, фиксируя каждый новый максимум. При
                            достижении заданного вами уровня просадки от последнего максимума, система автоматически
                            сокращает позиции, защищая ваши средства.
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Section>
        <Section
            padding="120px 0 120px 0"
            lg-margin="0 0 0 0"
            lg-padding="80px 0 80px 0"
            background="#FFFFFF"
            quarkly-title="Price-20"
        >
            <Box padding="0px 120px 0px 120px" lg-flex-direction="column" lg-padding="0px 0 0px 0">
                <Box
                    min-width="100px"
                    min-height="100px"
                    display="flex"
                    justify-content="space-between"
                    margin="0px 0px 40px 0px"
                    md-flex-direction="column"
                >
                    <Text
                        font="normal 600 26px/1.3 --fontFamily-googleRubikMonoOne"
                        color="--darkL1"
                        lg-margin="0px 0px 20px 0px"
                        sm-margin="0px 0px 10px 0px"
                        margin="0px 0 25px 0"
                        width="25%"
                        md-width="100%"
                        md-margin="0 0 20px 0"
                    >
                        Базовый
                    </Text>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="50%"
                        margin="0px 0px 0px 10%"
                        padding="0px 50px 0px 0px"
                        md-margin="0 0 25px 0"
                        md-width="100%"
                    >
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — 1 счет
                        </Text>
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — проверка баланса 1 раз в минуту
                        </Text>
                        <Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — поддержка по email
                        </Text>
                    </Box>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="15%"
                        display="flex"
                        flex-direction="column"
                        align-items="flex-end"
                        md-width="100%"
                        md-align-items="flex-start"
                    >
                        <Text margin="0px 0px 3px 0px" font="normal 600 36px/1.2 --fontFamily-googleRobotoMono"
                              text-align="left">
                            $10
                        </Text>
                        <Text font="normal 400 18px/1.5 --fontFamily-sansHelvetica" color="--darkL1"
                              margin="0 0px 0 0px">
                            в месяц
                        </Text>
                    </Box>
                </Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    display="flex"
                    justify-content="space-between"
                    margin="0px 0px 40px 0px"
                    md-flex-direction="column"
                >
                    <Text
                        font="normal 600 26px/1.3 --fontFamily-googleRubikMonoOne"
                        color="--darkL1"
                        lg-margin="0px 0px 20px 0px"
                        sm-margin="0px 0px 10px 0px"
                        margin="0px 0 25px 0"
                        width="25%"
                        md-width="100%"
                        md-margin="0 0 20px 0"
                    >
                        Продвинутый
                    </Text>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="50%"
                        margin="0px 0px 0px 10%"
                        padding="0px 50px 0px 0px"
                        md-margin="0 0 25px 0"
                        md-width="100%"
                    >
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — до 5 счетов
                        </Text>
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — проверка баланса 1 раз в минуту
                        </Text>
                        <Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — поддержка по email
                        </Text>
                    </Box>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="15%"
                        display="flex"
                        flex-direction="column"
                        align-items="flex-end"
                        md-width="100%"
                        md-align-items="flex-start"
                    >
                        <Text margin="0px 0px 3px 0px" font="normal 600 36px/1.2 --fontFamily-googleRobotoMono"
                              text-align="left">
                            $40
                        </Text>
                        <Text font="normal 400 18px/1.5 --fontFamily-sansHelvetica" color="--darkL1"
                              margin="0 0px 0 0px">
                            в месяц
                        </Text>
                    </Box>
                </Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    display="flex"
                    justify-content="space-between"
                    margin="0px 0px 40px 0px"
                    md-flex-direction="column"
                >
                    <Text
                        font="normal 600 26px/1.3 --fontFamily-googleRubikMonoOne"
                        color="--darkL1"
                        lg-margin="0px 0px 20px 0px"
                        sm-margin="0px 0px 10px 0px"
                        margin="0px 0 25px 0"
                        width="25%"
                        md-width="100%"
                        md-margin="0 0 20px 0"
                    >
                        Надежный
                    </Text>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="50%"
                        margin="0px 0px 0px 10%"
                        padding="0px 50px 0px 0px"
                        md-margin="0 0 25px 0"
                        md-width="100%"
                    >
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — до 10 счетов
                        </Text>
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — проверка баланса в реальном времени
                        </Text>
                        <Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — поддержка по email и телеграм
                        </Text>
                    </Box>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="15%"
                        display="flex"
                        flex-direction="column"
                        align-items="flex-end"
                        md-width="100%"
                        md-align-items="flex-start"
                    >
                        <Text margin="0px 0px 3px 0px" font="normal 600 36px/1.2 --fontFamily-googleRobotoMono"
                              text-align="left">
                            $100
                        </Text>
                        <Text font="normal 400 18px/1.5 --fontFamily-sansHelvetica" color="--darkL1"
                              margin="0 0px 0 0px">
                            в месяц
                        </Text>
                    </Box>
                </Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    display="flex"
                    justify-content="space-between"
                    md-flex-direction="column"
                >
                    <Text
                        font="normal 600 26px/1.3 --fontFamily-googleRubikMonoOne"
                        color="--darkL1"
                        lg-margin="0px 0px 20px 0px"
                        sm-margin="0px 0px 10px 0px"
                        margin="0px 0 25px 0"
                        width="25%"
                        md-width="100%"
                        md-margin="0 0 20px 0"
                    >
                        Солидный
                    </Text>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="50%"
                        margin="0px 0px 0px 10%"
                        padding="0px 50px 0px 0px"
                        md-margin="0 0 25px 0"
                        md-width="100%"
                    >
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — до 30 счетов
                        </Text>
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — проверка баланса в реальном времени
                        </Text>
                        <Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                              color="--darkL1">
                            — поддержка по email и телеграм
                        </Text>
                    </Box>
                    <Box
                        min-width="100px"
                        min-height="100px"
                        width="15%"
                        display="flex"
                        flex-direction="column"
                        align-items="flex-end"
                        md-width="100%"
                        md-align-items="flex-start"
                    >
                        <Text margin="0px 0px 3px 0px" font="normal 600 36px/1.2 --fontFamily-googleRobotoMono"
                              text-align="left">
                            $250
                        </Text>
                        <Text font="normal 400 18px/1.5 --fontFamily-sansHelvetica" color="--darkL1"
                              margin="0 0px 0 0px">
                            в месяц
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Section>
        <Section padding="80px 0 0px 0" sm-padding="60px 0px 60px 0px" background="--color-light">
            <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap"/>
            <Box
                width="100%"
                flex-direction="column"
                justify-content="flex-start"
                align-items="flex-start"
                lg-width="100%"
                lg-align-items="flex-start"
                lg-margin="0px 0px 60px 0px"
                sm-margin="0px 0px 40px 0px"
                sm-padding="0px 0px 0px 0px"
                padding="0px 0px 16px 0px"
                lg-flex-direction="row"
                lg-flex-wrap="wrap"
            >
                <Text
                    margin="0px 0px 24px 0px"
                    color="--dark"
                    font="normal 100 36px/1.2 --fontFamily-googleRubikMonoOne"
                    lg-text-align="center"
                    sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
                    lg-width="100%"
                >
                    Как получить доступ?
                </Text>
                <Text
                    margin="00px 0px 48px 0px"
                    color="--greyD3"
                    font="normal 500 24px/1.5 --fontFamily-googleIbmPlexMono"
                    lg-text-align="center"
                    lg-width="100%"
                    text-align="justify"
                    align-self="auto"
                    display="block"
                    padding="20px 0px 0px 0px"
                >
                    Дорогие друзья,{"\n"}
                    <br/>
                    <br/>
                    Спасибо за ваш огромный интерес к нашему сервису! Стремясь предоставить каждому из вас высочайшее
                    качество обслуживания и надежную защиту ваших средств, мы внедрили систему подключения по листу
                    ожидания.
                    <br/>
                    <br/>
                    Хотите присоединиться? Просто нажмите на кнопку ниже и заполните короткую форму. Мы свяжемся с вами,
                    как только подойдет ваша очередь, и поможем оформить подписку и получить доступ в личный
                    кабинет.{"\n"}
                    <br/>
                    <br/>
                    Мы ценим ваше терпение и обещаем сделать процесс подключения максимально комфортным. Присоединяйтесь
                    к сообществу успешных трейдеров уже сегодня!{"\n"}
                    <br/>
                    <br/>
                    Нажимайте на кнопку, заполняйте форму, и до встречи на борту!
                </Text>
                <Box min-width="100px" min-height="100px" text-align="center">
                    <Button font="--headline2" background="--color-primary" border-radius="15px"
                            hover-background="--color-indigo"
                            onClick={(e) => {
                                showForm(e);
                            }}>
                        Записаться в лист ожидания
                    </Button>
                </Box>
                <Box
                    display="flex"
                    align-items="flex-start"
                    margin="0px 0px 32px 0px"
                    lg-width="50%"
                    lg-margin="0px 0px 0px 0px"
                    lg-padding="0px 16px 0px 0px"
                    md-width="100%"
                    md-margin="0px 0px 32px 0px"
                    md-padding="0px 0px 0px 0px"
                >
                    <Box margin="0px 0px 0px 22px"/>
                </Box>
                <Box
                    display="flex"
                    align-items="flex-start"
                    lg-width="50%"
                    lg-padding="0px 0px 0px 16px"
                    md-width="100%"
                    md-padding="0px 0px 0px 0px"
                >
                    <Box margin="0px 0px 0px 22px"/>
                </Box>
            </Box>
        </Section>
        <Section padding="100px 0 100px 0" background="#FFFFFFFF" quarkly-title="FAQ-8">
            <Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
                Частые вопросы
            </Text>
            <Box
                min-width="100px"
                min-height="100px"
                display="grid"
                grid-template-columns="repeat(3, 1fr)"
                grid-gap="50px 50px"
                lg-grid-template-columns="repeat(2, 1fr)"
                md-grid-template-columns="1fr"
                sm-grid-gap="35px 0"
                padding="50px 0px 0px 0px"
            >
                <Box min-width="100px" min-height="100px">
                    <Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
                        Почему Вы работаете только с фьючерсными счетами?
                    </Text>
                    <Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f"
                          text-align="justify">
                        Трейдеры несут самые большие потери при работе с фьючерсными счетами и использовании кредитного
                        плеча. Ликвидация позиции и всего баланса счета - одна из самых неприятных ситуаций. Когда весь
                        криптовалютный рынок падает, большая просадка по всем позициям приведет к гибели Вашего счета.
                        Мы стремимся к тому, чтобы у Вас оставались деньги для продолжения торговли. Кроме того, на
                        спот-счете ликвидация невозможна, и позиция может быть инвестирована столько, сколько Вы
                        захотите.
                    </Text>
                </Box>
                <Box min-width="100px" min-height="100px">
                    <Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
                        Нужен ли Вам доступ к моим средствам?
                    </Text>
                    <Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f"
                          text-align="justify">
                        Нет. Когда Вы создаете API-ключ на бирже, Вы даете нам право просматривать баланс и управлять
                        позициями на этом счете. У нас не будет возможности переводить средства.
                    </Text>
                </Box>
                <Box min-width="100px" min-height="100px">
                    <Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1"
                          text-align="left">
                        Можно ли создать несколько правил сокращения позиций?
                    </Text>
                    <Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f"
                          text-align="justify">
                        Да. В панели пользователя у Вас будет возможность настроить от одного до нескольких правил
                        сокращения позиций для различных просадок баланса.
                    </Text>
                </Box>
                <Box min-width="100px" min-height="100px">
                    <Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
                        Как работает бот после закрытия позиции?
                    </Text>
                    <Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f"
                          text-align="justify">
                        Бот работает непрерывно, пока Вы не остановите его из пользовательской панели. После закрытия
                        всех позиций бот будет ждать, пока Вы не откроете новую позицию на бирже, в это время он
                        зафиксирует баланс и начнет работать по заданному Вами алгоритму. Он снова зафиксирует новые
                        максимумы и защитит Вас от падения.
                    </Text>
                </Box>
                <Box min-width="100px" min-height="100px">
                    <Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
                        Что произойдет, если бот уменьшит позицию и баланс снова начнет расти?
                    </Text>
                    <Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f"
                          text-align="justify">
                        Бот будет ждать обновления максимального баланса, который он зафиксировал ранее, или ждать
                        снижения до заданного уровня защиты.
                    </Text>
                </Box>
                <Box min-width="100px" min-height="100px">
                    <Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
                        У меня возникли вопросы, как мне их задать?
                    </Text>
                    <Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f"
                          text-align="justify">
                        Когда Вы зарегистрируетесь в списке ожидания, у Вас будет возможность написать нам сообщение с
                        Вашим вопросом.
                    </Text>
                </Box>
            </Box>
        </Section>
        <Section padding="50px 0 50px 0" quarkly-title="Footer-2" background="--color-light">
            <Box display="flex" justify-content="space-between" sm-flex-direction="column">
                <Text
                    margin="0px 0px 0px 0px"
                    font="--base"
                    color="#5a5d64"
                    sm-margin="0px 0px 15px 0px"
                    sm-text-align="center"
                >
                    © 2024. IndexAny.com. All rights reserved.
                </Text>
            </Box>
        </Section>
        <RawHtml>
            <style place={"endOfHead"} rawKey={"6686aea51d712a002335db86"}>
                {":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
            </style>
        </RawHtml>
    </Theme>;
});