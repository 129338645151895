async function postTelegramData(data = {}) {

    const url = 'https://kslukgrih5xid2ijqazpczomae0qkbbi.lambda-url.eu-central-1.on.aws/'

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        // headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        // },
        redirect: "follow",
        body: JSON.stringify(data)
    });

    return await response.json();
}

export default async function postToTelegram(data) {
    try {
        return await postTelegramData(data);
    } catch (error) {
        console.error('Error:', error);
        return {error: error};
    }
}